/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #007bff;
	--ion-color-primary-rgb: 0,123,255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #006ce0;
	--ion-color-primary-tint: #1a88ff;

    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45,211,111;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;
  
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255,196,9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
  
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235,68,90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-color-primary-darken: #0063cc;
	--ion-color-primary-darken-rgb: 0,99,204;
	--ion-color-primary-darken-contrast: #ffffff;
	--ion-color-primary-darken-contrast-rgb: 255,255,255;
	--ion-color-primary-darken-shade: #0057b4;
	--ion-color-primary-darken-tint: #1a73d1;
    
    --ion-font-family: 'Roboto', sans-serif;
    --ion-background-color: #f5f5f5;
    --ion-item-background: white;
}

.ion-color-primary-darken {
    --ion-color-base: var(--ion-color-primary-darken);
    --ion-color-base-rgb: var(--ion-color-primary-darken-rgb);
    --ion-color-contrast: var(--ion-color-primary-darken-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-darken-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-darken-shade);
    --ion-color-tint: var(--ion-color-primary-darken-tint);
}
