// Typography Utilities - for quick prototype, avoid in production!

$fthin: 300;
$freg: 400;
$fsemibold: 500;
$fbold:700;


$rem10: 0.71rem;
$rem12: 0.8571rem;
$rem14: 1rem;
$rem16: 1.142rem;
$rem18: 1.285rem;
$rem20: 1.428rem;
$rem24: 1.714rem;
$rem26: 1.857rem;
$rem32: 2.285rem;
$rem36: 2.571rem;


html {
  scroll-behavior: smooth;
  font-size: 14px;
}

body {
  font-weight: $freg;
}

::selection {
  background: $primary;
  color: #fff;
}

.f12 {
  font-size: $rem12 !important;
}

.f14 {
  font-size: 1rem !important;
}

.f16 {
  font-size: $rem16 !important;
}

.f20 {
  font-size: $rem20 !important;
}

.f24 {
  font-size: $rem24 !important;
}

.f32 {
  font-size: $rem32 !important;
}

.c-gray {
  color: #FFFFFF99;
}
.text-current { color: currentColor; }


// .center{
//   @extend .text-center;
// }

.title-group {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

// Typography mixin - For faster development defined by designer.

@mixin title-case-0 {
  // 24 Title / Title Case / Roboto Bold;
  font-size: $rem26 !important;
  font-weight: $fbold !important;
  line-height: 28px !important;
}

@mixin title-case-1 {
  // 20 Title / Title Case / Roboto Bold;
  font-size: $rem20;
  font-weight: $fbold;
  line-height: 24px;
}


@mixin title-case-2 {

  // 16 Subtitle / Title Case / Roboto Medium
  font-size: $rem16;
  font-weight: $fsemibold; //default 500
  line-height: 20px;
  color: $gray-800;
}


@mixin title-case-3 {

  // 12 Subtitle / Title Case / Roboto Medium
  font-size: $rem12;
  font-weight: $fsemibold; //default 500
  line-height: 16px;
  color: $gray-800;
}



.title-case-0 {
  @include title-case-0;
}

.title-case-1 {
  @include title-case-1;
}

.title-case-2 {
  @include title-case-2;
}

.title-case-3 {
  @include title-case-3;
}


//U body

@mixin u-body-1 {
  font-size: $rem18; //18
  font-weight: 400;
  color: $gray-600;
  line-height: 1.2rem;
}


@mixin u-body-2 {
  font-size: $rem16; //16px
  font-weight: 400;
  color: $gray-600;
  line-height: $rem26;
}

// 12 Body / None / Roboto Regular
@mixin u-body-3 {
  font-size: $rem12; //12px
  font-weight: 400;
  color: $gray-600;
  line-height: 1.2rem;
}

.u-body-1 {
  @include u-body-1;
}

.u-body-2 {
  @include u-body-2;
}

.u-body-3 {
  @include u-body-3;
}

// Links Utilities - link plain clear the link underline, etc
.a-plain {
  text-decoration: none !important;
  color:inherit !important;
}

a.btn-primary{ //force non href a tag to have white text
  color: white;
}

//styleName: 14 Body / None / Roboto Regular;


.fthin {
  font-weight: $fthin;
}

.freg {
  font-weight: $freg;
}

.fsemibold {
  font-weight: $fsemibold;
}

.fbold {
  font-weight: $fbold;
}