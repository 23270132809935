@import "./bootstrap/bootstrap.scss";

@import "./mixin";
@import "./typography";

@import "./../css/DatePicker.css";
@import "./../css/DateRangePicker.css";
@import "./../css/Calendar.css";
@import "./../css/TimePicker.css";

body { overscroll-behavior-x: none; }
label:has(+ :required):after, .asterisk{ content: ' *'; color: red; }
input, textarea, .alert-input.sc-ion-alert-md, ion-select, .react-date-picker, .react-daterange-picker, .react-time-picker {
	border-radius: 8px;
	padding: 4px 10px;
	background-color: #EEF3F7;
	border: 2px solid #ebebeb;
	outline: none;
	height: 40px;
	font-weight: 500;
	width: 100%;
    color: #212121;
	&:focus { border-bottom-color: $primary; }
    &:disabled { background-color: #f3f3f3; color: #878787; }
}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] { -moz-appearance: textfield; }
select { outline: none; }
textarea { height: 50px; }
ion-card { border-radius: 16px; }
ion-modal::part(content) {
    --background : white;
    --border-radius: 8px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}
ion-list {
    padding: 0 !important;
    ion-item { --background: white; }
}
ion-loading {
    --width: 82px !important;
    .loading-wrapper { border-radius: 16px !important; }
}
ion-alert{
    --background: white !important;
    --max-width: 375px !important;
    --min-width: 300px !important;
    @include for-md { --min-width: 375px !important; }
    .alert-wrapper{
        border-radius: 16px !important;
        .alert-head{ padding: 24px 24px 10px 24px; }
        .alert-message{ padding: 10px 24px 24px 24px !important; user-select: text; }
        .alert-input-group { padding-top: 0px; }
        .alert-button-group{
            border-top: 1px solid #ddd;
            padding: 16px 24px;
            display: flex;
            justify-content: space-between;
            .alert-button{
                width: calc(50% - 4px);
                text-align: center;
                border-radius: 25px;
                margin: 0;
                padding: 14px 10px;
                span{ display: block; text-transform: capitalize; }
                &:first-of-type{
                    color: var(--ion-color-primary);
                    border: 1px solid var(--ion-color-primary);
                    &:hover{ color: #007bff !important; background-color: #007bff36; }
                }
                &:last-of-type{
                    background-color: var(--ion-color-primary);
                    color: #fff !important;
                    &:hover{ background-color: #0069d9; color: #fff !important; }
                }
                &:only-child{ margin-left: auto; }
            }
        }
    }
}
::-webkit-scrollbar {
	width: 6px;
	padding-left: 4px;
	display:none;
}
::-webkit-scrollbar-track { background: transparent; }
::-webkit-scrollbar-thumb {
	height: 56px;
	border-radius: 8px;
	border: 4px solid transparent;
	background-clip: content-box;
	background-color: #dbdbdb;
}
::-webkit-scrollbar-thumb:hover { background-color: #737373; }
@media (min-width: 992px) {
	::-webkit-scrollbar {
	  width: 16px;
	  display:inherit;
	}
    ion-modal:part(content) {
        --min-width: 750px;
        --height: fit-content;
    }
}
.h-40 { height: 40px; }
.cursor-pointer { cursor: pointer; }
.rounded-2x { border-radius: 8px; }
.btn-loading { cursor:not-allowed !important;pointer-events:none }
.btn-loading:before {
    display: inline-block;
    content: "";
    margin-bottom: 3px;
    margin-right: 8px;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    border: .25em solid;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
}
label.is-invalid {
    color: var(--ion-color-danger-shade);
	font-weight: 500;
    font-size: .8571rem;
    margin-bottom: 0;
}
.bg-primary-darken { background: #0063cc; }
.sa-item {
    border-radius: 8px;
    --min-height: 39px;
    --background: #EEF3F7;
};
.ag-input-field-input, input[id^="react-select"] { text-transform: uppercase; }
