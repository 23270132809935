//  Media query Mixin
// -Quick way to create media query in scss-
// Usage: https://itnext.io/writing-media-queries-with-sass-mixins-3ea591ea3ea4

// bootstrap 4.5 standards

// usage:
@mixin for-320 {
  @media (min-width: 320px) {
    @content;
  }
}
@mixin for-480 {
  @media (min-width: 480px) {
    @content;
  }
}

@mixin for-sm {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin for-md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin for-lg {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin for-xl {
  @media (min-width: 1200px) {
    @content;
  }
}

// Link state management MIXIN

@mixin links($link, $visited, $hover, $active) {
  & {
    color: $link;
    &:visited {
      color: $visited;
    }
    &:hover {
      color: $hover;
    }
    &:active,
    &:focus {
      color: $active;
    }
  }
}

@mixin link-plain($color : inherit !important) {
  & {
    color: $color;
    text-decoration: none;

    &:visited {
      color: $color;
      text-decoration: none;
    }
    &:hover {
      color: $color;
      text-decoration: none;
    }
    &:active,
    &:focus {
      color: $color;
      text-decoration: none;
    }
  }
}

//Hide scrollbar
@mixin hideScrollbar {
  // https://blogs.msdn.microsoft.com/kurlak/2013/11/03/hiding-vertical-scrollbars-with-pure-css-in-chrome-ie-6-firefox-opera-and-safari/
  // There is a CSS rule that can hide scrollbars in Webkit-based browsers (Chrome and Safari).
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
  // There is a CSS rule that can hide scrollbars in IE 10+.
  -ms-overflow-style: none;

  // Use -ms-autohiding-scrollbar if you wish to display on hover.
  // -ms-overflow-style: -ms-autohiding-scrollbar;

  // There used to be a CSS rule that could hide scrollbars in Firefox, but it has since been deprecated.
  scrollbar-width: none;
}

@mixin miniRoundedScrollbar{
  &::-webkit-scrollbar { width: 8px; }
  &::-webkit-scrollbar-thumb{ border-radius: 8px; }
  &::-webkit-scrollbar-track{ border-radius: 8px; background: rgba(0, 0, 0, 0.12); }
}

//Materialize CSS box shadow mixin

@mixin BoxShadow($dp) {
  transition:box-shadow 0.2s;
  
  @if $dp==0 {
    box-shadow: none;
  } @else if $dp==1 {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 0 2px 0 rgba(0, 0, 0, 0.07);
  } @else if $dp==2 {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.06),
      0 2px 4px 0 rgba(0, 0, 0, 0.07);
  } @else if $dp==3 {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
      0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  } @else if $dp==4 {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.10), 
     0 4px 5px 0 rgba(0, 0, 0, 0.06),     
      0 2px 4px 0 rgba(0, 0, 0, 0.07);
  } @else if $dp==6 {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  } @else if $dp==8 {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  } @else if $dp==9 {
    box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.14),
      0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px -3px rgba(0, 0, 0, 0.2);
  } @else if $dp==12 {
    box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14),
      0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
  } @else if $dp==16 {
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
      0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  } @else if $dp==24 {
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  }
}

// Limit line height and ellipsis
@mixin limit-line-clamp($line){
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// EXPERIMENTAL (DOESNT REALLY SUPPORTED, MIGHT NOT WORK AS INTENDED)
// Custom Scrollbar
@mixin custom-scrollbar($size : 5px, $foreground-color:#afafaf, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }

  //for Firefox
  scrollbar-color: $foreground-color $background-color;
  scrollbar-width: thin;
}

//Button variables
@mixin btn-thumb-friendly-padding{
  padding-top:12px;
  padding-bottom:12px;
}

// Gives border for easier layouting
@mixin debug-layout {
  outline:1px solid black;
  
  > * {
      outline:1px solid red;
      > * {
          outline:1px solid green;
          > * {
              outline:1px solid pink;
              > * {
                  outline:1px solid purple;
                  > * {
                      outline:1px solid red;
                      > * {
                          outline:1px solid green;
                          > * {
                              outline:1px solid pink;
                              > * {
                                  outline:1px solid purple;
                                  > * {
                                      outline:1px solid red;
                                      > * {
                                          outline:1px solid green;
                                          > * {
                                              outline:1px solid pink;
                                              > * {
                                                  outline:1px solid purple;
                                                  > * {
                                                      outline:1px solid red;
                                                      > * {
                                                          outline:1px solid green;
                                                          > * {
                                                              outline:1px solid pink;
                                                              > * {
                                                                  outline:1px solid purple;
                                                              }
                                                          }
                                                      }
                                                  }
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  }
}

@mixin input-group-colored($groupColor, $buttonColor, $textColor : $groupColor, $placeholderColor : $textColor) {

  .input-colored,
  input {
    height: 40px;
    background-color: transparent;
    color: $textColor;

    border: 2px solid $groupColor;
    font-weight: 300;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: $placeholderColor;
    }
  }

  .input-group-append,
  .input-group-prepend {
    display: flex;

    button {
      color: $buttonColor;
      background-color: $groupColor;
    }

    .icon {
      color: $buttonColor;
      background-color: $groupColor;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      padding: 0 7px;
    }
  }

  .input-group-append {
    .icon {
      border-top-right-radius: $theme-border-radius * 2;
      border-bottom-right-radius: $theme-border-radius * 2;
    }

  }

  .input-group-prepend {
    .icon {
      border-top-left-radius: $theme-border-radius * 2;
      border-bottom-left-radius: $theme-border-radius * 2;
    }

  }
}

@mixin u-body-3 {
  font-size: 12px; //12px
  font-weight: 400;
  color: $gray-600;
  line-height: 1.2rem;
}